// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}

























































































































































































































































































































































































#app-container {
  position: relative;
  min-width: 1400px;
  max-width: 1900px;
  .resizer {
    position: absolute;
    height: 100%;
    top: 0;
    width: 5px;
    cursor: col-resize;
    &.resizer-right {
      right: 5px;
    }
    &.resizer-left {
      left: 5px;
    }
  }  
}

.list-requests {
  margin-top: 10px;

  .pager-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;

    .select {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 17px;

      .form-item-select {
        &__control {
          height: auto;
          background: none;
          border: none;
        }

        &__container {
          padding: 0 0 0 5px;
        }

        &__value {
          color: #1e72c2;
        }

        &__indicators {
          span::before {
            border-color: #1e72c2;
            border-width: 0 2px 2px 0;
          }
        }

        &__input {
          input {
            height: 0;
            width: 0;
          }
        }
      }
    }
  }

  .form-wizard-content--progress {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -21px 0 0 -27px;
  }

  a.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 20px;
    background: linear-gradient(263.67deg, #7a6afa 1.17%, #607aff 99.07%);
    border-radius: 4px;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;

    .icon-plus {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background: url("/img/icon/plus.svg") no-repeat center/cover;
    }
  }

  .form-item.search {
    .input-wrap {
      position: relative;
      width: 425px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -6px;
        opacity: 1;
        width: 12px;
        height: 12px;
        background: url("/img/icon/search.svg") no-repeat center;
      }
    }

    input {
      padding-right: 35px;
    }
  }

  &-empty {
    width: 580px;
    margin: 170px auto 0;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 26px;
      color: #000;
    }

    .descr {
      line-height: 25px;
    }

    .controls {
      margin-top: 40px;

      .button {
        display: inline-flex;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .controls {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 15px;
      }
    }

    .title {
      font-size: 25px;
      line-height: 43px;
      color: #000;

      span {
        display: block;
        font-size: 12px;
        line-height: 21px;
        color: #808080;
      }
    }
  }
}
